import * as React from 'react';
import { graphql, PageProps } from 'gatsby';

import Page from '../../components/Page';
import Form from '../../components/Form';
import Section from '../../components/Section';
import ExternalLink from '../../components/ExternalLink';
import getCmsDocuments from '../../cms';
import { useContactInfo } from '../../hooks/data';
import type { ContactInfo, ContactIntro } from '../../types';
import * as styles from './index.module.css';

const LINKS = [
  {
    title: 'Vlaamse overheid bouwen',
    description: 'Website van de Vlaamse overheid: bouwen, wonen & energie.',
    img: '/assets/svg/vlaanderen-logo.svg',
    url: 'https://www.vlaanderen.be/bouwen-wonen-en-energie',
  },
  {
    title: 'Energiesparen.be',
    description: 'Zoek uw subsidie en nog vele info over energie.',
    img: '/assets/svg/vlaanderen-logo.svg',
    url: 'https://apps.energiesparen.be/subsidies/subsidiemodule',
  },
];

const ContactPage: React.FC<PageProps<ContactInfo>> = ({ data }) => {
  const documents = getCmsDocuments(data) as ContactIntro[];
  const [{ title, html }] = documents;
  const [{ address_1, address_2, email, phone }] = useContactInfo();

  return (
    <Page title={title} description={html}>
      <Section>
        <Section.Title>{title}</Section.Title>
        <Section.Description dangerouslySetInnerHTML={{ __html: html }} />
        <div className={styles.mapContainer}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2518.11978211443!2d3.7088473157276267!3d50.86598217953494!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c30eab991d2f55%3A0xd9d8736574343fb4!2sThienpondt%20Frank!5e0!3m2!1snl!2sbe!4v1624891223502!5m2!1snl!2sbe"
            width="100%"
            height="450"
            style={{ border: 0 }}
            allowFullScreen
            loading="lazy"
          ></iframe>
          <div className={styles.contactInfoContainer}>
            <div className={styles.contactInfoItem}>
              <div>
                <label>Adres</label>
                <p>{address_1}</p>
                <p>{address_2}</p>
              </div>
            </div>
            <div className={styles.contactInfoItem}>
              <label>E-mailadres</label>
              <p>{email}</p>
            </div>
            <div className={styles.contactInfoItem}>
              <label>Telefoon</label>
              <p>{phone}</p>
            </div>
          </div>
        </div>
      </Section>
      <Section>
        <Section.Title>Neem direct contact op</Section.Title>
        <Form />
      </Section>
      <Section>
        <Section.Title>Handige links</Section.Title>
        <Section.Description>
          Hieronder zijn enkele handige links opgelijst die u kan raadplegen
        </Section.Description>
        <ul className={styles.list}>
          {LINKS.map((link) => (
            <li key={link.url} className={styles.item}>
              <div className={styles.linkContainer}>
                <div className={styles.infoContainer}>
                  <img src={link.img} />
                  <div>
                    <p>{link.title}</p>
                    <span>{link.description}</span>
                  </div>
                </div>
                <ExternalLink
                  variant="outline"
                  href={link.url}
                  className={styles.url}
                >
                  Bezoeken
                </ExternalLink>
              </div>
            </li>
          ))}
        </ul>
      </Section>
    </Page>
  );
};

export default ContactPage;

export const pageQuery = graphql`
  query getContactPage {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(contact/intro)/" } }
    ) {
      edges {
        node {
          html
          frontmatter {
            title
          }
          fields {
            filePath
          }
        }
      }
    }
  }
`;
