import * as React from 'react';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';
import { nopeResolver } from '@hookform/resolvers/nope';
import * as nope from 'nope-validator';

import Button from '../Button';
import * as styles from './index.module.css';

const getValidationSchema = () => {
  const schema = nope.object().shape({
    name: nope.string().required('Uw naam is verplicht'),
    email: nope
      .string()
      .email('Ongeldig e-mailadres')
      .required('Uw e-mailadres is verplicht'),
    subject: nope
      .string()
      .min(2, 'Onderwerp moet minstens 2 letters bevatten')
      .required('Onderwerp is verplicht'),
    message: nope.string().required('Bericht is verplicht'),
  });

  return schema;
};

type FormData = {
  name: string;
  email: string;
  subject: string;
  message: string;
};

const encode = (data: Record<string, any>) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};

const Input = React.forwardRef<
  HTMLInputElement,
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >
>(({ className, ...rest }, ref) => {
  const classNames = clsx(styles.input, className);

  return <input {...rest} ref={ref} className={classNames}></input>;
});

const Field: React.FC<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> = ({ children, className, ...rest }) => {
  const classNames = clsx(styles.field, className);

  return (
    <div {...rest} className={classNames}>
      {children}
    </div>
  );
};

const submitToNetlify = async (values: FormData) => {
  try {
    await fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        ...values,
        subject: 'Nieuwe e-mail',
        'form-name': 'contact-form',
      }),
    });
  } catch (error) {
    console.error(error);
  }
};

const ContactForm: React.FC = () => {
  const validationSchema = getValidationSchema();
  const submitButtonRef = React.useRef<HTMLButtonElement>(null);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitSuccessful, isSubmitting },
  } = useForm<FormData>({
    resolver: nopeResolver(validationSchema),
  });

  const handleFormSubmit = handleSubmit(async (values) => {
    await submitToNetlify(values);
    submitButtonRef.current?.blur();
    reset({});
  });

  return (
    <form
      className={styles.form}
      onSubmit={handleFormSubmit}
      data-netlify="true"
      name="contact-form"
    >
      <Field className={styles.nameField}>
        <label htmlFor="name">Naam</label>
        <Input id="name" {...register('name')} placeholder="Peter Jansen" />
        <p className={styles.errorMessage}>{errors.name?.message}</p>
      </Field>
      <Field className={styles.emailField}>
        <label htmlFor="email">E-mailadres</label>
        <Input
          id="email"
          {...register('email')}
          placeholder="peter@jansen.be"
        />
        <p className={styles.errorMessage}>{errors.email?.message}</p>
      </Field>
      <Field className={styles.subjectField}>
        <label htmlFor="subject">Onderwerp</label>
        <Input
          id="subject"
          {...register('subject')}
          name="subject"
          placeholder="Inspectie herstelling zuigerpompen"
        />
        <p className={styles.errorMessage}>{errors.subject?.message}</p>
      </Field>
      <Field className={styles.messageField}>
        <label htmlFor="message">Bericht</label>
        <textarea
          id="message"
          {...register('message')}
          name="message"
          placeholder="Bericht"
          className={styles.input}
          rows={6}
        />
        <p className={styles.errorMessage}>{errors.message?.message}</p>
      </Field>
      <div className={styles.footer}>
        <Button
          ref={submitButtonRef}
          title="Verzend"
          variant={isSubmitSuccessful ? 'success' : 'primary'}
          disabled={isSubmitting}
          className={styles.submitButton}
        >
          {isSubmitSuccessful ? 'Verzonden' : 'Verzenden'}
        </Button>
        {isSubmitSuccessful && (
          <p className={styles.successMessage}>
            Succesvol verzonden, u kan binnenkort een antwoord verwachten
          </p>
        )}
      </div>
    </form>
  );
};

export default ContactForm;
